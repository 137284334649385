import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { LoadingStatus, OrderType } from 'common/enums/enums'

const ukPhrases = [
  {
    text: 'Вода - єдиний підходящий напій для мудрого.',
    author: 'Генрі Девід Торо',
  },
  {
    text: 'Воду ми починаємо цінувати не раніше, ніж висихає криниця.',
    author: 'Томас Фуллер',
  },
  {
    text: 'Ковток води відкриває двері раю.',
    author: "Норвезьке прислів'я",
  },
  {
    text: 'Вода, у тебе немає ні смаку, ні кольору, ні запаху, тебе неможливо описати, тобою насолоджуються, не знаючи, що ти таке. Не можна сказати, що ти потрібна для життя: ти - саме життя. Ти сповнюєш нас радістю, яку не пояснити нашими почуттями.',
    author: 'Антуан де Сент-Екзюпері',
  },
  {
    text: 'У холодній воді - лікування, попередження захворювань, вона зміцнює тіло і зберігає бадьорість духу.',
    author: 'Авл Корнелій Цельс',
  },
  {
    text: 'Без води й борщу не звариш.',
    author: 'Народна мудрість',
  },
  {
    text: 'Чиста вода - це найперша медицина.',
    author: "Словацьке прислів'я",
  },
]

const enPhrases = [
  {
    text: 'Water, thou hast no taste, no color, no odor; canst not be defined, art relished while ever mysterious. Not necessary to life, but rather life itself, thou fillest us with a gratification that exceeds the delight of the senses.',
    author: 'Antoine de Saint-Exupéry',
  },
  {
    text: 'We never know the worth of water till the well is dry.',
    author: 'Thomas Fuller',
  },
  {
    text: 'I believe that water is the only drink for a wise man.',
    author: 'Henry David Thoreau',
  },
  {
    text: 'Water is the lifeblood of our bodies, our economy, our nation and our well-being.',
    author: 'Stephen Johnson',
  },
  {
    text: "You are water, I'm water, we're all water in different containers.",
    author: 'Yoko Ono',
  },
  {
    text: 'Thousands have lived without love, not one without water.',
    author: 'W. H. Auden',
  },
  {
    text: "Pure water is the world's first and foremost medicine.",
    author: 'Slovakian Proverb',
  },
]

const mapLocaleToPhrases = {
  en: enPhrases,
  uk: ukPhrases,
}

export const generateRandomPhrase = (locale) => {
  const phrases = mapLocaleToPhrases[locale]

  return phrases[Math.floor(Math.random() * phrases.length)]
}

export const isLoading = (loading) => loading === LoadingStatus.LOADING

export const switchOrderType = (currentOrderBy, newOrderBy, orderType) => {
  if (currentOrderBy === newOrderBy) {
    if (orderType === OrderType.NONE) {
      return { orderType: OrderType.ASC, orderBy: currentOrderBy }
    }

    if (orderType === OrderType.ASC) {
      return { orderType: OrderType.DESC, orderBy: currentOrderBy }
    }

    return { orderType: OrderType.NONE, orderBy: null }
  }

  return { orderType: OrderType.ASC, orderBy: newOrderBy }
}

export const getValueOrDash = (value) => {
  if (value != null && value !== '') {
    return value
  }
  return '–'
}

export const getIdOrDefault = (value) => {
  if (value != null && value !== '') {
    return value
  }
  return 'Unknown'
}

export const extractHoursNumber = (value) => {
  if (value) {
    return +value % 24
  }

  if (value === 0) {
    return 0
  }

  return '–'
}

export const extractDaysNumber = (value) => {
  if (value) {
    return Math.floor(+value / 24)
  }

  if (value === 0) {
    return 0
  }

  return '–'
}

export const roundToTwo = (num) => {
  if (num) {
    return parseFloat(num.toFixed(2))
  }

  return num
}

export const round = (num) => {
  if (num) {
    return Math.round(num)
  }

  return num
}

const systemState = {
  '0': 'UNKNOWN',
  '1': 'OPERATION',
  '2': 'FLUSHING',
  '3': 'PERMEATE_FLUSHING',
  '4': 'STAND_BY',
  '5': 'ALARM',
  '6': 'STOP',
  '7': 'NO_CONNECTION',
  '8': 'DEVICE_LOCKED',
  '9': 'DRYRUN',
  '10': 'LEAKAGE',
  '11': 'NO_POWER',
  '12': 'ATTENTION',
}

const systemStateColor = {
  '0': 'transparent',
  '1': '#64ba5f',
  '2': '#64ba5f',
  '3': '#64ba5f',
  '4': '#64ba5f',
  '5': '#d54c48',
  '6': '#64ba5f',
  '7': '#78919c',
  '8': '#d54c48',
  '9': '#d54c48',
  '10': '#d54c48',
  '11': '#d54c48',
  '12': '#f0ad4e',
}

const alarmType = {
  '-1': 'NO_DATA',
  '0': 'NO_ALARM',
  '1': 'HIGH_FEED_TDS',
  '2': 'DRY_RUN',
  '3': 'HIGH_PRESSURE',
  '4': 'DEVLOCKED',
  '5': 'HIGH_PERMEATE_TDS',
  '6': 'HIGH_WATER_TEMPERATURE',
  '7': 'HIGH_FLOWRATE_DECREASE',
  '8': 'HIGH_TDS_DEVIATION',
  '9': 'NO_ANTISCALANT',
  '10': 'ANTISCALANT_OK',
  '11': 'MOTOR_CT',
  '12': 'FREEZE',
  '13': 'GSM_ERROR',
  '14': 'FLOW_RATE_BELOW_THRESHOLD',
}

export const convertSystemStateCodeToLabel = (code) => {
  if (code !== null && code !== undefined && code !== '–') {
    return systemState[code.toString()]
  }

  return 'UNKNOWN'
}

export const convertSystemStateCodeToColor = (code) => {
  if (code !== null && code !== undefined && code !== '–') {
    return systemStateColor[code.toString()]
  }

  return 'transparent'
}

export const convertAlarmTypeCodeToLabel = (code) => {
  if (code !== null && code !== undefined && code !== '–') {
    const alarm = alarmType[code.toString()]
    return alarm || 'NO_DATA'
  }

  return 'NO_DATA'
}

export const numberWithSpaces = (x) => {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

export const getDetailedStateTabIndex = (tabsAccesses, index) => {
  const tabsWithNoAccess = tabsAccesses.filter((access) => !access)
  return index - tabsWithNoAccess.length
}

export const getFormattedDate = (date) => {
  dayjs.extend(utc)

  if (!date) {
    return dayjs(dayjs.utc().local().format().substring(0, 10)).format(
      'DD/MM/YYYY'
    )
  }

  return dayjs(date.utc().local().format().substring(0, 10)).format(
    'DD/MM/YYYY'
  )
}

export const convertUnixTimestempToString = (unixTimestamp) => {
  const a = new Date(unixTimestamp * 1000)
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ]
  const year = a.getFullYear()
  const month = months[a.getMonth()]
  const date = a.getDate()
  const hour = a.getHours()
  const min = `0${a.getMinutes()}`.slice(-2)

  const sec = `0${a.getSeconds()}`.slice(-2)

  const time = `${date}/${month}/${year}  ${hour}:${min}:${sec}`
  return time
}
